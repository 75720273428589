import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetActivities, usePostActivities } from './generated/apiData';
import { Activities as Activity } from './generated/types';
import ScreenWrapper from './ScreenWrapper';

type Props = {
	item: Activity;
};

const ActivitiesListItem: FC<Props> = ({ item }) => {
	return <Link to={`/activity/${item.idActivities}`}>{item.Name ?? ''}</Link>;
};

const Activities: FC = () => {
	const activities = useGetActivities({});
	const postActivity = usePostActivities({});
	const [newActivity, setNewActivity] = useState<string>();
	const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
		if (newActivity) {
			postActivity
				.perform(
					{},
					{
						Name: newActivity,
					},
				)
				.then(() => {
					setNewActivity('');
					activities.invalidateCache();
				});
		}
		event.preventDefault();
	};
	return (
		<ScreenWrapper title="Activiteiten">
			{activities.request.networkStatus === 'success' ? (
				<>
					{activities.data?.map((item) => (
						<div key={item.idActivities.toString()}>
							<ActivitiesListItem item={item} />
						</div>
					))}
					<hr />
					<form onSubmit={onSubmit}>
						<label>
							Nieuw:
							<br />
							<input
								type="text"
								onChange={({ target: { value } }) =>
									setNewActivity(value)
								}
								value={newActivity}
							/>
						</label>
					</form>
				</>
			) : (
				<>Loading</>
			)}
		</ScreenWrapper>
	);
};

export default Activities;
