import { Action, applyMiddleware, combineReducers, createStore, Store } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension'
import { configure, reducer, State } from "react-api-data";
import { useMemo } from "react";
import { globalConfig, endpointConfig } from "./endpointConfig";

let store: Store<{
  apiData: State;
}, Action>;

export const initStore = () => {
  store = createStore(
    combineReducers({ apiData: reducer }),
    {},
    composeWithDevTools(applyMiddleware(thunkMiddleware)),
  );
  store.dispatch(configure(globalConfig, endpointConfig));
  return store;
}

export const useStore = () => {
  return useMemo(() => store ?? initStore(), []);
}
