import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	useGetActivitiesIdActivities,
	useGetPurposes,
	usePostPurposes,
} from './generated/apiData';
import ScreenWrapper from './ScreenWrapper';

type Params = {
	activityId: string;
};

const Activity: FC = () => {
	let activityId = parseInt(useParams<Params>().activityId ?? '', 10);
	const activity = useGetActivitiesIdActivities({
		idActivities: activityId,
	});
	const getActivityPurposes = useGetPurposes({
		idActivities: activityId,
	});

	const getAllPurposes = useGetPurposes({});
	const postPurposes = usePostPurposes();
	const [newPurpose, setNewPurpose] = useState<string>('');
	const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
		addNewPurpose(newPurpose);
		event.preventDefault();
	};

	const addNewPurpose = (purpose: string) => {
		if (activityId) {
			postPurposes
				.perform(
					{},
					{
						idActivities: activityId,
						Name: purpose,
					},
				)
				.then(() => {
					getActivityPurposes.invalidateCache();
				});
		}
	};
	return (
		<ScreenWrapper title={activity.data?.Name ?? 'Activiteit'} backURL="/">
			{activity.request.networkStatus === 'success' ? (
				<>
					<ul>
						{getActivityPurposes.data?.map((purpose) => (
							<li key={purpose.idPurposes}>{purpose.Name}</li>
						))}
					</ul>
					<hr />
					<form onSubmit={onSubmit}>
						<label>
							Nieuw:
							<br />
							<input
								type="text"
								onChange={({ target: { value } }) =>
									setNewPurpose(value)
								}
								value={newPurpose}
							/>
						</label>
					</form>
					{getAllPurposes.data
						?.filter(
							(item) =>
								item.idActivities !== activityId &&
								item.Name?.toLowerCase().includes(
									newPurpose.toLowerCase(),
								),
						)
						.map((item) => (
							<div
								onClick={() =>
									item.idPurposes && addNewPurpose(item.Name)
								}
								style={{
									textDecoration: 'underline',
									cursor: 'pointer',
								}}
								key={item.idPurposes.toString()}
							>
								{item.Name}
							</div>
						))}
				</>
			) : (
				<>Loading</>
			)}
		</ScreenWrapper>
	);
};

export default Activity;
