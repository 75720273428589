import { FC } from 'react';
import { Provider } from 'react-redux';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import Activity from './Activity';
import Activities from './Activities';
import { initStore } from './store';

const store = initStore();

const App: FC = () => {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Activities />} />
					<Route path="activity/:activityId" element={<Activity />} />
				</Routes>
			</BrowserRouter>
		</Provider>
	);
};

export default App;
