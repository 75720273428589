/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * Purposes API
 * OpenAPI spec version: 1.0.0
 */
import {
  useApiData,
  HookOptions,
  Method
} from 'react-api-data'
import type {
  Activities,
  PostActivities,
  Delete,
  Purposes,
  PostPurposes
} from './types'


/**
 * Activities
 * @summary Get all items in Activities
 */
export const useGetActivities = (
    params: {}, options?: HookOptions 
) => useApiData<Activities[], void, {}, unknown>('getActivities', params, options);

/**
 * Activities
 * @summary Add item to Activities
 */
export const usePostActivities = (
    params: {} = {}, options?: HookOptions 
) => useApiData<Activities, void, {}, PostActivities>('postActivities', params, options);

/**
 * Activities
 * @summary Get one items in Activities
 */
export const useGetActivitiesIdActivities = (
    params: {idActivities: number}, options?: HookOptions 
) => useApiData<Activities, void, {idActivities: number}, unknown>('getActivitiesIdActivities', params, options);

/**
 * Activities
 * @summary Update one item in Activities
 */
export const usePutActivitiesIdActivities = (
    params: Partial<{idActivities: number}> = {}, options?: HookOptions 
) => useApiData<Activities, void, Partial<{idActivities: number}>, PostActivities>('putActivitiesIdActivities', params, options);

/**
 * Activities
 * @summary Delete one item from Activities
 */
export const useDeleteActivitiesIdActivities = (
    params: Partial<{idActivities: number}> = {}, options?: HookOptions 
) => useApiData<Delete, void, Partial<{idActivities: number}>, unknown>('deleteActivitiesIdActivities', params, options);

/**
 * Purposes
 * @summary Get all items in Purposes
 */
export const useGetPurposes = (
    params: {}, options?: HookOptions 
) => useApiData<Purposes[], void, {}, unknown>('getPurposes', params, options);

/**
 * Purposes
 * @summary Add item to Purposes
 */
export const usePostPurposes = (
    params: {} = {}, options?: HookOptions 
) => useApiData<Purposes, void, {}, PostPurposes>('postPurposes', params, options);

/**
 * Purposes
 * @summary Get one items in Purposes
 */
export const useGetPurposesIdPurposes = (
    params: {idPurposes: number}, options?: HookOptions 
) => useApiData<Purposes, void, {idPurposes: number}, unknown>('getPurposesIdPurposes', params, options);

/**
 * Purposes
 * @summary Update one item in Purposes
 */
export const usePutPurposesIdPurposes = (
    params: Partial<{idPurposes: number}> = {}, options?: HookOptions 
) => useApiData<Purposes, void, Partial<{idPurposes: number}>, PostPurposes>('putPurposesIdPurposes', params, options);

/**
 * Purposes
 * @summary Delete one item from Purposes
 */
export const useDeletePurposesIdPurposes = (
    params: Partial<{idPurposes: number}> = {}, options?: HookOptions 
) => useApiData<Delete, void, Partial<{idPurposes: number}>, unknown>('deletePurposesIdPurposes', params, options);

const getUrl = (path: string) => `${path}`;
export const endpointConfig = {
    getActivities: {
        url: getUrl('/api/v1/activities'),
        method: 'GET' as Method,
    },
    postActivities: {
        url: getUrl('/api/v1/activities'),
        method: 'POST' as Method,
    },
    getActivitiesIdActivities: {
        url: getUrl('/api/v1/activities/:idActivities'),
        method: 'GET' as Method,
    },
    putActivitiesIdActivities: {
        url: getUrl('/api/v1/activities/:idActivities'),
        method: 'PUT' as Method,
    },
    deleteActivitiesIdActivities: {
        url: getUrl('/api/v1/activities/:idActivities'),
        method: 'DELETE' as Method,
    },
    getPurposes: {
        url: getUrl('/api/v1/purposes'),
        method: 'GET' as Method,
    },
    postPurposes: {
        url: getUrl('/api/v1/purposes'),
        method: 'POST' as Method,
    },
    getPurposesIdPurposes: {
        url: getUrl('/api/v1/purposes/:idPurposes'),
        method: 'GET' as Method,
    },
    putPurposesIdPurposes: {
        url: getUrl('/api/v1/purposes/:idPurposes'),
        method: 'PUT' as Method,
    },
    deletePurposesIdPurposes: {
        url: getUrl('/api/v1/purposes/:idPurposes'),
        method: 'DELETE' as Method,
    },
};
