import { EndpointConfig, GlobalConfig, Method } from "react-api-data";

const getUrl = (path: string) => `https://purposes.backapps.nl${path}`;

export const globalConfig: GlobalConfig = {
    setHeaders: () => ({
        // 'Authorization': 'Bearer 35f6b8b84c69a07c38d7628fc93a42b614de7de35461ab53357c08de5c707ddafac30a685d5bcf77bba2906e205e2f297b314b86f8fe6961da4757a9470b672b4d2e70b42683ea4be4d8c5cc986653efb5c73ebe9de63692e39f11aaceccb1138033ba1f83e60cc4391a87afa9d8c371eb96e61577d6a685b3f74b3b07fbf8bd'
    })
}
export const endpointConfig: Record<string, EndpointConfig> = {
    getActivities: {
        url: getUrl('/api/v1/activities'),
        method: 'GET' as Method,
    },
    postActivities: {
        url: getUrl('/api/v1/activities'),
        method: 'POST' as Method,
    },
    getActivitiesIdActivities: {
        url: getUrl('/api/v1/activities/:idActivities'),
        method: 'GET' as Method,
    },
    putActivitiesIdActivities: {
        url: getUrl('/api/v1/activities/:idActivities'),
        method: 'PUT' as Method,
    },
    deleteActivitiesIdActivities: {
        url: getUrl('/api/v1/activities/:idActivities'),
        method: 'DELETE' as Method,
    },
    getPurposes: {
        url: getUrl('/api/v1/purposes'),
        method: 'GET' as Method,
    },
    postPurposes: {
        url: getUrl('/api/v1/purposes'),
        method: 'POST' as Method,
    },
    getPurposesIdPurposes: {
        url: getUrl('/api/v1/purposes/:idPurposes'),
        method: 'GET' as Method,
    },
    putPurposesIdPurposes: {
        url: getUrl('/api/v1/purposes/:idPurposes'),
        method: 'PUT' as Method,
    },
    deletePurposesIdPurposes: {
        url: getUrl('/api/v1/purposes/:idPurposes'),
        method: 'DELETE' as Method,
    },
};