import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

type Props = {
	backURL?: string;
	title: string;
	children: ReactNode;
};

const ScreenWrapper: FC<Props> = ({ backURL, title, children }) => {
	return (
		<div style={{ maxWidth: 800, margin: 'auto' }}>
			{backURL && <Link to={backURL}>Terug</Link>}
			<h1>{title}</h1>
			{children}
		</div>
	);
};
export default ScreenWrapper;
